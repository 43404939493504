import React, {CSSProperties, FunctionComponent} from "react";
import styled from "styled-components";

import logo from "../../../../content/assets/images/logo.png";

interface AvatarProps {
  alt: string;
  style?: CSSProperties;
}

const StyledAvatar = styled.img<AvatarProps>`
  max-width: 55px;
  border-radius: 100%;
`;

const Avatar: FunctionComponent<AvatarProps> = ({alt, style}) => {
  return <StyledAvatar src={logo} alt={alt} style={style} />;
};

export default Avatar;
